import PropTypes from 'prop-types';
import Input from './component';
import { defaultPropTypes, elevationPropType, sizePropType } from '../propTypes';

Input.defaultProps = {
  disabled: false,
  loading: false,
  size: 'md',
  elevation: 'md', // TODO: Not implemented yet
  className: '',
  as: 'input',
  htmlType: 'text',
  defaultValue: '',
  placeholder: '',
  showPlaceholderOnFocus: true,
  type: 'text',
  wrapperStyle: {},
  width: '100%',
  onChange: () => null,
  onFocus: () => null,
  onBlur: () => null
};

Input.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  size: sizePropType,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  elevation: elevationPropType,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  as: PropTypes.oneOf(['input', 'textarea']),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number', 'date']),
  wrapperStyle: PropTypes.object,
  ...defaultPropTypes
};

export default Input;
