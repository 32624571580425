import React, { forwardRef, useState } from 'react';
import {
  StyledInput,
  StyledInputPlaceholder,
  StyledInputWrapper
} from './styles';

const InputComponent = forwardRef(
  (
    {
      as,
      className,
      icon,
      iconPosition,
      loading,
      disabled,
      onChange,
      style,
      wrapperStyle,
      size,
      value,
      width,
      defaultValue,
      placeholder,
      showPlaceholderOnFocus,
      onFocus,
      htmlType,
      elevation,
      onBlur,
      ...rest
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(defaultValue);
    const [focused, setFocused] = useState(false);
    const handleInternalOnChange = e => setInternalValue(e.target.value);
    const handleOnFocus = e => {
      onFocus(e);
      setFocused(true);
    };
    const handleOnBlur = e => {
      onBlur(e);
      setFocused(false);
    };
    const handleOnChange =
      value !== undefined ? onChange : handleInternalOnChange;
    const val = value !== undefined ? value : internalValue;

    const isFocused =
      focused || (val !== undefined && val !== '' && val !== null);

    const attrs = {
      as,
      hasPlaceholder: (placeholder),
      showPlaceholderOnFocus: (showPlaceholderOnFocus),
      loading,
      size,
      focused: isFocused
    };

    return (
      <StyledInputWrapper style={{width, ...wrapperStyle}}>
        {placeholder && (
          <StyledInputPlaceholder attrs={attrs}>
            {placeholder}
          </StyledInputPlaceholder>
        )}
        <StyledInput
          {...rest}
          as={as}
          ref={ref}
          type={htmlType}
          disabled={disabled}
          className={className}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          style={style}
          value={value !== undefined ? value : internalValue}
          onChange={handleOnChange}
          attrs={attrs}
        />
      </StyledInputWrapper>
    );
  }
);

export default InputComponent;
