import React from 'react';
import Layout from '../components/layout';
import { Box, Button, Container, Heading, Text } from 'shared-ui';
import { Input } from 'shared-ui/input';
import { Cell } from 'styled-css-grid';
import Grid from 'shared-ui/src/components/ui/Grid';
import { withResponsive } from 'shared-ui/src/contexts/responsive';
//import Image from '../components/image';
import SEO from '../components/seo';
import SubscribeForm from '../components/SubscribeForm';

import { Button as BumbagButton } from 'bumbag/Button';

const IndexPage = ({responsive}) => {
  return (
    <Layout>
      <SEO/>
      <Box vfull vcenter center withHeader>
        <Container type="narrow">
          <Heading level={1} size={0}>I'm building a <span className="text-highlight">new way</span> to stay healthy at your desk</Heading>
          <Text paragraph>
            <p>Sitting is the new smoking. Standing desks and gym sessions are not sufficient to combat a desk-based lifestyle. The only solution is to move more frequently.</p>
            <p>Learn more about the <a href="http://ankle.io/sitting">dangers of sitting</a> in my latest blog post.</p>
          </Text>

          <BumbagButton>Bumbag Button!</BumbagButton>

          <SubscribeForm/>

        </Container>
      </Box>
    </Layout>
  );
};

/*
<Grid gap="sm" columns={responsive !== 'sm' ? `2fr 1fr` : `1fr`} spacing={{top: 'lg'}}>
            <Cell>
              <Input placeholder={`Email address`}/>
            </Cell>

            <Cell style={{display: 'flex', alignItems: 'flex-end'}}>
              <Button type="brand">Subscribe for updates</Button>
            </Cell>
          </Grid>
 */

export default withResponsive(IndexPage);
