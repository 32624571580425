import Grid from 'shared-ui/src/components/ui/Grid/component';
import { withTheme } from 'styled-components';
import { spacingPropType } from '../propTypes';

Grid.defaultProps = {
  style: {}
};

Grid.propTypes = {
  spacing: spacingPropType,
  gap: spacingPropType,
  columnGap: spacingPropType,
  rowGap: spacingPropType
};

export default withTheme(Grid);
