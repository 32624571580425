import React, { forwardRef } from 'react';
import { Grid } from 'styled-css-grid';
import { getThemeGridStyles } from './styles';
import { marginSpacing, spacing } from '../../../utils/styles';

const GridComponent = forwardRef(
  (
    {
      theme,
      gap,
      spacing: spacingProp,
      columnGap,
      style,
      rowGap,
      ...rest},
    ref
  ) => {
    let props = {
      ...getThemeGridStyles(theme),
      style: {
        ...style,
        margin: marginSpacing(theme, spacingProp)
      }
    };
    if(gap) props.gap = spacing(theme, gap);
    if(columnGap) props.columnGap = spacing(theme, columnGap);
    if(rowGap) props.rowGap = spacing(theme, rowGap);
    props = { ...props, ...rest };

    return (
      <Grid
        ref={ref}
        {...props}
      />
    );
  }
);

export default GridComponent;
