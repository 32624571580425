import React from 'react';
import styled, { css } from 'styled-components';
import { removePx } from '../../../utils/strings';
import { sanitizePropsWithBase } from '../../../utils/utils';

const getInputProps = ({ attrs: { size = 'base' }, theme: { global: { controls }, input } }) => {
  const sanitizedSize = sanitizePropsWithBase(size);
  const sizeProps = input.size[sanitizedSize];
  const controlsProps = controls.size[sanitizedSize];

  return {
    ...sizeProps,
    ...controlsProps
  };
};

const colorStyle = ({theme: { input }}) => {
  const { borderColor, focusBorderColor, color, background } = input;
  return css`
    ${borderColor && css `border-color: ${borderColor};`}
    ${color && css `color: ${color};`}
    ${background && css `background: ${background};`}
    
    
    &:focus {
      ${focusBorderColor && css `border-color: ${focusBorderColor};`}
    }
  `;
};

const fontStyle = props => {
  const font = getInputProps(props);
  return css`
    font-family: ${props.theme.global.font.fontFamily};
    font-size: ${font.fontSize};
    font-weight: ${font.fontWeight};
  `;
};


const sizeStyle = props => {
  const { attrs: { hasPlaceholder, showPlaceholderOnFocus, as } } = props;
  const { height, horizontalPadding, borderRadius, borderWidth } = getInputProps(props);

  let paddingTop = hasPlaceholder ? as === 'textarea' ? `1.25em` : `0.75em` : 0;
  if(showPlaceholderOnFocus === false) {
    paddingTop = 0;
  }
  return css`
    line-height: ${as === 'textarea' ? '1.5em' : height};
    height: ${as === 'textarea' ? 'auto' : height};
    padding: ${paddingTop} ${horizontalPadding} 0 ${horizontalPadding};
    border-width: ${borderWidth};
    border-radius: ${borderRadius};
    ${as === 'textarea' && css`min-height: ${removePx(height) * 2}px;`}
  `;
};

const disabledStyle = () => {
  return css`
    opacity: ${props => props.theme.global.opacity.strong};
    &:hover {
      cursor: not-allowed;
    }
  `;
};

const loadingStyle = () => {
  return css`
    opacity: ${props => props.theme.global.opacity.strong};
    &:hover {
      cursor: not-allowed;
    }
  `;
};

const placeholderTransformStyle = (props, scale = 1) => {
  const lineHeight = parseFloat(props.theme.global.font.lineHeight);
  const { height, horizontalPadding, fontSize } = getInputProps(props);
  const heightVal = removePx(height);
  const fontSizeVal = removePx(fontSize);
  const scaledHeight = scale * (fontSizeVal * lineHeight);
  let yTransform = (heightVal - fontSizeVal * lineHeight) / 2;
  yTransform = scale !== 1 ? yTransform - scaledHeight / 2 : yTransform;
  return css`
    transform: translate(${horizontalPadding}, ${yTransform}px) scale(${scale});
  `;
};

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledInputPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  color: ${({theme}) => theme.input.placeholder.color};
  pointer-events: none;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1), opacity 0.05s linear, -webkit-transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
  
  ${props => placeholderTransformStyle(props, 1)}
  ${props => props.attrs.focused ? props.attrs.showPlaceholderOnFocus ? css`${placeholderTransformStyle(props, 0.7)}` : css`opacity: 0;` : ``}
`;

export const StyledInput = styled.input`
  display: block;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  -webkit-transition: box-shadow .2s linear, border-color .25s ease-out;
  transition: box-shadow .2s linear, border-color .25s ease-out;
  border-style:solid;
  
  ${props => props.disabled && disabledStyle(props)}
  ${props => props.attrs.loading && loadingStyle(props)}
  ${props => sizeStyle(props)}
  ${props => fontStyle(props)}
  ${props => colorStyle(props)}
`;
